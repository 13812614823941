
import Vue from "vue";
import { mapActions, mapState, mapGetters } from "vuex";
import { ModuleNames } from "@/services/store-helper";
import { ActionTypes } from "@/store/messages/action-types";

export default Vue.extend({
  name: "QuickMessageList",
  data() {
    return {
      loading: false
    };
  },
  methods: {
    ...mapActions({
      loadQuickTemplatesAction:
        ModuleNames.MESSAGE_MODULE + ActionTypes.LOAD_QUICK_TEMPLATES
    }),
    edit(message: any) {
      this.$emit("editClicked", message);
    },
    deleteClick(templateId: string) {
      this.$emit("deleteClicked", templateId);
    },
    fetchTemplates() {
      this.loading = true;
      this.loadQuickTemplatesAction()
        .then(response => {
          // this.template = response.data.data;
          this.loading = false;
        })
        .catch(error => {
          console.error(error);
          this.loading = false;
        });
    }
  },
  computed: {
    // TODO: Refactor to use State instead
    ...mapGetters({
      templates: "Message/quickTemplates",
      loadedTemplates: "Message/quickTemplatesLoaded"
    })
  },
  created() {
    if (!this.loadedTemplates) {
      this.fetchTemplates();
    }
  }
});
