<script>
import Vue from "vue";
import api from "@/api";
import { Success, Warn } from "@/services/notif-utils";
export default Vue.extend({
  name: "SelectUserInput",
  data() {
    return {
      options: [],
      selected: null,
      staffLoading: false,
      operatorName: "",
    };
  },
  watch: {
    selected() {
      if (this.selected) {
        this.operatorName = this.selected["operatorName"];
      }
    },
  },
  computed: {
    selectedUserId() {
      if (this.selected) {
        return this.selected["userId"];
      }
      return "";
    },
  },
  methods: {
    getUsers() {
      this.options = [];
      api.Manager.users()
        .then((response) => {
          const data = response.data.users;
          console.log(data);
          for (const user of data) {
            console.log(user["username"]);
            this.options.push({
              label: user["username"],
              value: user["username"],
              operatorName: user["operator_name"],
              userId: user["id"],
            });
          }
        })
        .catch((err) => {
          console.log(err);
          Warn("Error Loading Users");
        });
    },
    saveOperatorName() {
      this.staffLoading = true;
      if (this.selectedUserId) {
        const data = {
          /* eslint-disable camelcase */
          operator_name: this.operatorName,
          id: this.selectedUserId,
        };
        api.Manager.postOperatorName(data)
          .then(async () => {
            this.staffLoading = false;
            Success("Successfully Add Operator Name");
            await this.getUsers();
          })
          .catch((err) => {
            Warn("Failed to add");
            this.staffLoading = false;
          });
      }
    },
  },
  created() {
    this.getUsers();
  },
});
</script>
<template>
  <div class="q-gutter-md">
    <q-select filled v-model="selected" :options="options">
      <template v-slot:no-option>
        <q-item>
          <q-item-section> No users Loaded </q-item-section>
        </q-item>
      </template>
    </q-select>
    <q-input
      filled
      counter
      v-model="operatorName"
      label="Staff Operator Name"
      hint="Staff Operator name"
      lazy-rules
      :rules="[(val) => val.length < 20 || 'Limit Reached']"
    />
    <q-btn
      :loading="staffLoading"
      label="Save New Operator Name"
      type="submit"
      color="positive"
      @click="saveOperatorName"
    />
  </div>
</template>
