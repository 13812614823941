
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import { ActionTypes } from "@/store/messages/action-types";
import { Success, Warn } from "@/services/notif-utils";
import GlossaryList from "@/components/GlossaryList.vue";

export default Vue.extend({
  name: "GlossaryTab",
  components: {
    GlossaryList
  },
  data() {
    return {
      loading: false,
      term: "",
      definition: "",
      update: false,
      processLoading: false,
      currentTermId: ""
    };
  },
  computed: {
    glossary() {
      return this.$store.state.Message.glossary;
    }
  },
  methods: {
    ...mapActions({
      loadGlossaryAction: "Message/" + ActionTypes.LOAD_GLOSSARY,
      updateAction: "Message/" + ActionTypes.UPDATE_GLOSSARY,
      deleteAction: "Message/" + ActionTypes.DELETE_GLOSSARY,
      saveAction: "Message/" + ActionTypes.SAVE_GLOSSARY
    }),
    submit() {
      if (this.update) {
        return this.updateTerm();
      }
      return this.saveNewTerm();
    },
    saveNewTerm() {
      this.processLoading = true;
      const data = {
        term: this.term,
        definition: this.definition
      };
      this.saveAction(data)
        .then(response => {
          Success("Successfully Saved New Term!");
          this.processLoading = false;
        })
        .catch(error => {
          console.log(error);
          Warn("Unable to Save New Term");
          this.processLoading = false;
        });
    },
    reset() {
      this.update = false;
      this.currentTermId = "";
      this.term = "";
      this.definition = "";
    },
    editTerm(term: any) {
      this.update = true;
      this.currentTermId = term.termId;
      this.term = term.term;
      this.definition = term.definition;
    },
    deleteTerm(termId: string) {
      this.deleteAction(termId)
        .then(response => {
          Success("Successfully Deleted Term");
        })
        .catch(err => {
          Warn("Unable to Delete Term");
        });
    },
    updateTerm() {
      this.processLoading = true;
      const data = {
        termId: this.currentTermId,
        term: this.term,
        definition: this.definition
      };
      this.updateAction(data)
        .then(response => {
          Success("Successfuly Update Term");
          this.processLoading = false;
        })
        .catch(error => {
          console.log(error);
          Warn("Unable to update");
          this.processLoading = false;
        });
    },
    getButtonLabel() {
      if (this.update) {
        return "Update Term";
      }
      return "Add New Term";
    }
  }
});
