
import Vue from "vue";
import Api from "@/api";
import SelectUsersInput from "@/components/SelectUsersInput.vue";
import KeyboardShortcuts from "@/models/KeyboardShortcuts";
import { mapActions } from "vuex";
import { ModuleNames } from "@/services/store-helper";
import { ActionTypes } from "@/store/messages/action-types";
import { Success } from "@/services/notif-utils";
import { Warn } from "@/services/notif-utils";
import QuickMessageTemplate from "@/components/QuickMessageTemplate.vue";
import GlossaryTab from "@/views/GlossaryTab.vue";
import QuickTemplatesTab from "@/views/QuickTemplatesTab.vue";
import ModalSubVars from "@/components/ModalSubVars.vue";
import api from "@/api";
export default Vue.extend({
  name: "AdminSettings",
  components: {
    // QuickMessageTemplate,
    QuickTemplatesTab,
    SelectUsersInput,
    ModalSubVars,
    GlossaryTab,
  },
  data() {
    return {
      loading: false,
      nodeSelected: false,
      thumbStyle: {
        right: "4px",
        borderRadius: "5px",
        backgroundColor: "#027be3",
        width: "5px",
        opacity: 0.75,
      },
      barStyle: {
        right: "2px",
        borderRadius: "9px",
        backgroundColor: "#027be3",
        width: "9px",
        opacity: 0.2,
      },
      editTemplateLabel: false,
      chosenReply: "",
      replyKey: "",
      users: [],
      update: false,
      templateLoading: false,
      staffLoading: false,
      operatorName: "",
      id: null,
      tab: "suggested_replies",
      label: "",
      messageBody: "",
      selected: "",
      ticked: [],
      expanded: [],
      searchTerm: "",
      expandedKeys: [],
      replies: [],
      templateLabel: "",
      currentActionIndex: "",
      editReply: false,
      selectedNode: "",
      isNewNode: false,
      deletePrompt: false,
      loadingDeleteBtn: false,
      nodeToDelete: {},
      savingNewNode: false,
      isNewCategory: false,
      savingReply: false,
      viewVariables: false,
    };
  },
  computed: {
    suggestedReplies(): any {
      const tree = this.$store.state.General.tree;
      if (tree !== null && tree.length > 0) {
        return this.newTree(tree);
      }
      return [];
    },
    getReplies(): any {
      const replies = this.$store.state.General.replies;
      return replies;
    },
  },
  methods: {
    ...mapActions({
      updateAction:
        ModuleNames.MESSAGE_MODULE + ActionTypes.UPDATE_QUICK_TEMPLATES,
      deleteAction:
        ModuleNames.MESSAGE_MODULE + ActionTypes.DELETE_FROM_QUICK_TEMPLATES,
      loadReplies: "General/LOAD_NEW_REPLIES",
      loadTree: "General/LOAD_TEMPLATE_TREE",
      loadSubVars: "General/LOAD_SUBSTITUTE_VARS",
    }),
    keyPress(event: KeyboardEvent) {
      const keyMap: KeyboardShortcuts[] = [
        {
          key: "o",
          fn: this.appendOperatorName,
        },
        {
          key: "g",
          fn: this.openGlossary,
        },
      ];
      if (event.ctrlKey) {
        const keyPressed = keyMap.find(({ key }) => {
          return key === event.key;
        });
        if (keyPressed) {
          event.preventDefault();
          keyPressed.fn();
        } else {
          return;
        }
      }
    },
    openGlossary() {
      this.viewVariables = !this.viewVariables;
    },
    appendOperatorName() {
      const singOutString = "\n \n<operator_name>";
      this.chosenReply += singOutString;
    },
    insertVar(variable: string) {
      const varString = " " + variable;
      this.chosenReply += varString;
    },
    deleteNodeBtn(node: any) {
      this.selectedNode = node.description;
      this.nodeToDelete = node;
      this.deletePrompt = true;
    },
    newCategory() {
      this.nodeSelected = true;
      this.currentActionIndex = "";
      this.selectedNode = "New Category";
      this.templateLabel = "";
      this.isNewNode = false;
      this.isNewCategory = true;
      this.editTemplateLabel = true;
      this.editReply = false;
    },
    deleteNode() {
      this.loadingDeleteBtn = true;
      api.MessageTemplates.deleteNode(this.nodeToDelete)
        .then(async () => {
          await this.loadTree();
          await this.loadReplies();
          this.nodeToDelete = {};
          this.loadingDeleteBtn = false;
          Success("Successfully Deleted");
          this.deletePrompt = false;
        })
        .catch((err) => {
          Warn("Failed to delete");
          this.nodeToDelete = {};
          this.loadingDeleteBtn = false;
          this.deletePrompt = false;
        });
    },
    saveTemplates() {
      this.savingNewNode = true;
      const data = this.suggestedReplies;
      Api.MessageTemplates.addTemplates(data)
        .then((response) => {
          this.savingNewNode = false;
          Success("This was succesfull!!");
        })
        .catch((error) => {
          this.savingNewNode = false;
          console.log(error);
          Warn("Bumped into an error with templates");
        });
      this.savingNewNode = false;
    },
    saveNewNode() {
      // alert(0);
      this.savingNewNode = true;
      let reply = undefined;
      if (this.chosenReply) {
        //NOTE: OLD Deprecated functionality, moving away from
        // positional index  Dependancy
        /* 
        const prefix = "r";
        const getLatestKey = this.$store.state.General.newReplies[
          "latestReplyKey"
        ];
        console.log(getLatestKey);
        const re = /\d+/g;
        const digit = ~~getLatestKey.match(re);
        console.log(digit);
        const newKey = prefix + (digit + 1);
        console.log(newKey);
         reply = { oldKey: getLatestKey, key: newKey, reply: this.chosenReply };
        */
        reply = { reply: this.chosenReply, type: "DT" };
      }
      const data = {
        nodeId: this.currentActionIndex,
        description: this.templateLabel,
        reply: reply,
      };
      Api.MessageTemplates.saveNewTemplate(data)
        .then(async () => {
          await this.loadTree();
          await this.loadReplies();
          Success("Successfully Saved new Template");
          this.savingNewNode = false;
        })
        .catch((err) => {
          console.log(err);
          Warn("Failed to Save New Template");
          this.savingNewNode = false;
        });
      // this.savingNewNode = false;
    },
    addNewNode(node?: any) {
      this.nodeSelected = true;
      this.isNewCategory = false;
      this.isNewNode = true;
      if (node != undefined) {
        this.selectedNode = node.description;
        this.currentActionIndex = node.node_id;
      }
      this.editTemplateLabel = true;
      this.templateLabel = "";
      this.chosenReply = "";
      this.editReply = true;
    },

    updateNode(node: any) {
      this.nodeSelected = true;
      this.isNewNode = false;
      this.currentActionIndex = node.node_id;
      const replyCheck = node.reply;
      this.templateLabel = node.description;
      this.selectedNode = node.description;
      const kids = node.children;
      if (replyCheck) {
        this.editReply = true;
        const getReply = this.$store.getters["General/getNewReply"](node.reply);
        this.chosenReply = getReply;
        this.replyKey = node.reply;
        return;
      }
      if (kids) {
        return (this.editReply = false);
      }
      this.editReply = false;
      this.chosenReply = "";
    },
    saveNewLabel() {
      this.loading = true;
      if (this.isNewCategory) {
        const data = { description: this.templateLabel };
        Api.MessageTemplates.addNewCategory(data)
          .then(async () => {
            this.loadTree()
              .then(() => {
                this.loading = false;
              })
              .catch((e) => {
                Error("Faile to load tree");
                this.loading = false;
              });
            Success("Succesfully Added New category");
            this.isNewCategory = false;
            return;
          })
          .catch((err) => {
            console.log("err");
            Warn("Error Saving Category");
            this.loading = false;
          });
        // this.loading = false;
        this.isNewCategory = false;
        return;
      }
      const data = {
        nodeId: this.currentActionIndex,
        description: this.templateLabel,
      };
      this.$store
        .dispatch("General/UPDATE_TREE", data)
        .then(() => {
          Success("Updated Label Successfully!");
          // this.currentActionIndex = "";
          this.editTemplateLabel = false;
          this.loadTree();
        })
        .catch((err) => {
          Warn("Error Saving!");
          console.log(err);
        });
    },
    updateReply() {
      const data = { key: this.replyKey, reply: this.chosenReply };
      Api.MessageTemplates.updateReply(data)
        .then(() => {
          this.loadReplies().then(() => {
            Success("Successfully edited Reply Message!");
          });
        })
        .catch((err) => {
          console.log(err);
          Warn("Failed to Save Reply message");
        });
    },
    saveReply() {
      const replies = this.getReplies;
      Api.MessageTemplates.addReply({ replies: [replies] })
        .then((response) => {
          console.log(response);
          Success("Replies Loaded Successfully");
        })
        .catch((err) => {
          console.log(err);
          Warn("Bumped into an erro with replies");
        });
      // const data = {
      //   replyKey:this.replyKey,

      // }
    },
    newTree(tree: any): any {
      if (this.searchTerm.length == 0 && this.replies.length == 0) {
        this.expandedKeys.splice(0);
        return tree;
      }

      const finalTree = [];
      for (const item of tree) {
        if (this.searchTerm.length > 0) {
          if (
            item.description
              .toLowerCase()
              .indexOf(this.searchTerm.toLowerCase()) > -1
          ) {
            //@ts-expect-error
            this.expandedKeys.push(item.index);
            finalTree.push(item);
            continue;
          }
        }

        if (
          this.replies.length > 0 &&
          item.reply !== null &&
          //@ts-expect-error
          this.replies.indexOf(item.reply) > -1
        ) {
          //@ts-expect-error
          this.expandedKeys.push(item.index);
          finalTree.push(item);
          continue;
        }

        // get only relevant children
        if (item.children) {
          const matchingChildren = this.newTree(item.children);
          const newItem = Object.assign({}, item, {
            children: matchingChildren,
          });
          if (matchingChildren.length > 0) {
            //@ts-expect-error
            this.expandedKeys.push(item.index);
            finalTree.push(newItem);
          }
        }
      }

      return finalTree;
    },

    updateSelected(key: any) {
      console.log(key);
      //@ts-expect-error
      const node = this.$refs.tree.getNodeByKey(key);
      console.log(node);
      if (node.reply) {
        const replyTemplate = this.$store.getters["General/getNewReply"](
          node.reply
        );
        this.templateLabel = node.description;
        this.chosenReply = replyTemplate;
        this.replyKey = node.reply;
        // this.fetchReply();
      } else {
        this.chosenReply = "";
      }
    },
    placeValues(payload: any) {
      this.update = true;
      console.log(payload.messageBody);
      this.id = payload.id;
      this.label = payload.label;
      this.messageBody = payload.messageBody;
    },
    staffSubmit() {
      console.log("Submit");
    },
    staffReset() {
      console.log("Reset");
    },
    onReset() {
      (this.update = false), (this.label = "");
      this.messageBody = "";
    },
    templateSubmit() {
      this.templateLoading = true;
      let data: object = {};
      if (this.update) {
        data = {
          id: this.id,
          label: this.label,
          messageBody: this.messageBody,
        };
      } else {
        data = {
          label: this.label,
          messageBody: this.messageBody,
        };
      }
      this.updateAction(data)
        .then((res) => {
          console.log(res);
          this.templateLoading = false;
          this.label = res.label;
          this.messageBody = res.messageBody;
          this.$q.notify({
            position: "top-right",
            type: "blue-7",
            message: "Succesfully added Template",
          });
        })
        .catch((error) => {
          console.log("Error Caught");
          this.templateLoading = false;
        });
    },
    deleteTemplate() {
      const data: object = { id: this.id };
      this.deleteAction(data).catch((error) => {
        console.log(error);
        this.$q.notify({
          position: "top-right",
          type: "warning",
          message: "Error deleting message",
        });
      });
    },
    // loadTree() {
    //   Api.MessageTemplates.getTree();
    // },
  },
  created() {
    window.addEventListener("keydown", this.keyPress);
    // @ts-expect-error
    this.keyPress(KeyboardEvent);
    this.loadReplies().catch((err) => {
      console.log(err);
      Warn("Failed To Load Replies, Won't work Correctly");
    });
    this.loadTree();
    this.loadSubVars().catch((err) => {
      console.log(err);
      Warn("Failed to load Substitute Variables");
    });
  },

  beforeDestroy() {
    window.removeEventListener("keydown", this.keyPress);
  },
});
