
import Vue from "vue";
import { mapActions, mapState, mapGetters } from "vuex";
import { ModuleNames } from "@/services/store-helper";
import { ActionTypes } from "@/store/messages/action-types";

export default Vue.extend({
  name: "QuickMessageList",
  data() {
    return {
      loading: false
    };
  },
  methods: {
    ...mapActions({
      loadGlossaryAction: "Message/" + ActionTypes.LOAD_GLOSSARY
    }),
    edit(message: any) {
      this.$emit("editClicked", message);
    },
    deleteClick(templateId: string) {
      this.$emit("deleteClicked", templateId);
    },
    fetchGlossary() {
      this.loading = true;
      this.loadGlossaryAction()
        .then(response => {
          // this.template = response.data.data;
          this.loading = false;
        })
        .catch(error => {
          console.error(error);
          this.loading = false;
        });
    }
  },
  computed: {
    glossary() {
      return this.$store.state.Message.glossary;
    },
    glossaryLoaded() {
      return this.$store.state.Message.glossaryLoaded;
    }
  },
  created() {
    if (!this.glossaryLoaded) {
      this.fetchGlossary();
    }
  }
});
