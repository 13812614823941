
import Vue from "vue";
import QuickTemplatesList from "@/components/QuickMessageTemplatesList.vue";
import { mapActions } from "vuex";
import { ActionTypes } from "@/store/messages/action-types";
import ModalSubVars from "@/components/ModalSubVars.vue";
import { ModuleNames } from "@/services/store-helper";
import { Info, Success, Warn } from "@/services/notif-utils";

export default Vue.extend({
  name: "QuickTemplateTab",
  components: {
    QuickTemplatesList,
    ModalSubVars
  },
  data() {
    return {
      label: "",
      messageBody: "",
      templateLoading: false,
      update: false,
      currentID: null,
      buttonType: "add",
      viewVariables: false
    };
  },
  methods: {
    ...mapActions({
      updateAction:
        ModuleNames.MESSAGE_MODULE + ActionTypes.UPDATE_QUICK_TEMPLATES,
      saveAction: ModuleNames.MESSAGE_MODULE + ActionTypes.SAVE_QUICK_TEMPLATE,
      deleteAction:
        ModuleNames.MESSAGE_MODULE + ActionTypes.DELETE_FROM_QUICK_TEMPLATES
    }),
    templateSubmit() {
      if (this.buttonType == "add") {
        return this.saveNewTemplate();
      }
      return this.updateTemplate();
      //
    },
    onReset() {
      this.buttonType = "add";
      this.label = "";
      this.messageBody = "";
    },
    deleteTemplate(templateId: string) {
      this.deleteAction(templateId)
        .then(response => {
          Success("Successfully Deleted!");
        })
        .catch(err => {
          console.log(err);
          Warn("Unable To Delete");
        });
    },
    editTemplate(message: any) {
      this.buttonType = "edit";
      this.label = message.label;
      this.messageBody = message.messageBody;
      this.currentID = message.templateId;
    },
    getButtonLabel() {
      if (this.buttonType == "edit") {
        return "Update Template";
      }
      return "Add New Template +";
    },
    saveNewTemplate() {
      this.templateLoading = true;

      const data = {
        label: this.label,
        messageBody: this.messageBody
      };
      this.saveAction(data)
        .then(response => {
          Success("Successfully Added New Template!");
          this.templateLoading = false;
        })
        .catch(error => {
          console.log(error);
          Warn("Unable to save");
          this.templateLoading = false;
        });
      //   this.updateAction(data).then((response) => {
      //     Success("Successfully Update Message");
      //   });
    },
    updateTemplate() {
      this.templateLoading = true;
      const data = {
        templateId: this.currentID,
        label: this.label,
        messageBody: this.messageBody
      };
      this.updateAction(data)
        .then(response => {
          Success("Successfully Updated Message");
          this.templateLoading = false;
        })
        .catch(err => {
          console.log(err);
          Warn("Failed TO Update");
          this.templateLoading = false;
        });
    }
  }
});
